exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-building-owners-js": () => import("./../../../src/pages/building-owners.js" /* webpackChunkName: "component---src-pages-building-owners-js" */),
  "component---src-pages-campaign-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/campaign/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-campaign-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-earnings-campaign-js": () => import("./../../../src/pages/earnings-campaign.js" /* webpackChunkName: "component---src-pages-earnings-campaign-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-pmsg-js": () => import("./../../../src/pages/pmsg.js" /* webpackChunkName: "component---src-pages-pmsg-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-why-edgegrid-js": () => import("./../../../src/pages/why-edgegrid.js" /* webpackChunkName: "component---src-pages-why-edgegrid-js" */)
}

